<template>
<div>
  <Row>
    <Col span="24">
      <Form :label-width="100" ref="AddForm">
        <FormItem label="角色名称" prop="role_name">
          <Input v-model="formItem.role_name" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="角色简介" prop="role_intro">
          <Input type="textarea" v-model="formItem.role_intro" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="上级角色" prop="exam_duration">
          <treeselect v-model="formItem.role_parent" :options="roles" style="width: 600px"/>
        </FormItem>

        <FormItem>
          <Button size="large" type="primary" @click="submit()" :disabled="btnDisabled">确定</Button>
        </FormItem>

      </Form>
    </Col>
  </Row>
</div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import api from "../../api/setup";
// import api from '../../api/setup';
export default {
  name: "AddRole",
  components: { Treeselect },
  data: function(){
    return {
      formItem: {
        role_name: '',
        role_intro: '',
        role_parent: 0
      },
      roles: [
        {id: 0,label: '顶级',}
      ]
    }
  },
  computed: {
    btnDisabled: function(){
      return this.formItem.role_name === '' || typeof this.formItem.role_parent === 'undefined';
    }
  },
  methods: {
    getList: function(){
      this.$http.get(api.getRoles).then(response => {
        if(response.data.success) {
          this.roles.push(...response.data.res);
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    submit: function(){
      this.$http.post(api.addRole, this.formItem).then(response => {
        if(response.data.success) {
          this.roles = [
            {id: 0,label: '顶级',}
          ]
          this.$Message.success('添加成功')
          this.getList();
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    }
  },
  mounted() {
    this.getList();
    this.$emit('lastPage', '/setup/roles')
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>

</style>